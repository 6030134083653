import { useLocaleChanging } from "~~/stores/global";

export function useWaitForPageTransitionEnd() {
  const localeChangingStore = useLocaleChanging();

  const promise = ref<Promise<boolean> | null>(null);
  const unwatch = ref<(() => void) | null>(null);

  onMounted(() => {
    promise.value = new Promise<boolean>((resolve) => {
      unwatch.value = watchEffect(() => {
        if (localeChangingStore.pageTransitionEnded) {
          resolve(true);
        }
      });
    });
    return { promise };
  });

  onBeforeUnmount(() => {
    unwatch.value?.();
  });

  return { promise };
}
